import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule} from '@ionic/angular';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CarNewFormPageModule} from './vehicle-manager/car-new-form/car-new-form.module';
import {CarService} from './services/Car.service';
import {HttpClientModule} from '@angular/common/http';
import {UserService} from './services/User.service';
import {HashLocationStrategy, LocationStrategy} from "@angular/common";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, CarNewFormPageModule, HttpClientModule],
  providers: [UserService, {provide: LocationStrategy, useClass: HashLocationStrategy}, CarService],
  bootstrap: [AppComponent],
})
export class AppModule {
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getAPIEndpoint() {
  //return 'http://localhost/';
  return 'https://postercar.com/api/public/';
}

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function getMapsApiKey() {
  return 'AIzaSyCOPDjnsxKOvO5oH1vOplmGSzp4eHNSSjM';
}
