import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {getAPIEndpoint} from '../app.module';

@Injectable()
export class UserService {
  constructor(
    private http: HttpClient
  ) {
  }

  /**
   * Return a list with all the posts items
   */
  public doLogin(user, pass): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'user/login', JSON.stringify({
        email: user,
        password: pass
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * Return a list with all the posts items
   */
  public fetchProfile(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'user/profile', JSON.stringify({
        token: localStorage.getItem('pca_token')
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * Return a list with all the posts items
   */
  public listAll(filters: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'user/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        filters: filters
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * Get one user
   */
  public fetch(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'user/fetch', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        id: id
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public save(user): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'user/save', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        user: user
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public delete(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'user/delete', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        id: id
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }
}
