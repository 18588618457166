import {Component, Input, OnInit} from '@angular/core';
import {LoadingController, ModalController, ToastController} from '@ionic/angular';
import {CarService} from 'src/app/services/Car.service';

@Component({
  selector: 'app-car-new-form',
  templateUrl: './car-new-form.page.html',
  styleUrls: ['./car-new-form.page.scss'],
})
export class CarNewFormPage implements OnInit {

  @Input() type: string;
  @Input() model: any;
  @Input() year: any;
  @Input() serie: any;
  @Input() engine: any;

  public newGen = {
    description: '',
    year_from: 2022,
    year_to: 2022
  };
  public newSerie = {
    name: '',
  };
  public newEngine = {
    engine: '',
    from: 2022,
    to: 2022
  }
  public newEquipment = {
    name: '',
    year: 2022
  }

  constructor(
    private modalCtrl: ModalController,
    private carSRV: CarService,
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController
  ) {
  }

  ngOnInit() {
  }

  public async saveGeneration() {
    let loading = await this.loadingCtrl.create({});
    loading.present();

    //
    this.carSRV.createGeneration(this.model, this.newGen).then(
      (data) => {
        loading.dismiss();
        this.modalCtrl.dismiss();
        this.toastCtrl.create(
          {
            message: "Generación creada correctamente",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
            location.reload();
          }
        );
      }
    ).catch(
      (error) => {
        loading.dismiss();
        this.toastCtrl.create(
          {
            message: "Error creando la generación, verifique los campos",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
          }
        );
      }
    )
  }

  public async saveSerie() {
    let loading = await this.loadingCtrl.create({});
    loading.present();

    //
    this.carSRV.createSerie(this.model, this.year, this.newSerie).then(
      (data) => {
        loading.dismiss();
        this.modalCtrl.dismiss();
        this.toastCtrl.create(
          {
            message: "Generación creada correctamente",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
            location.reload();
          }
        );
      }
    ).catch(
      (error) => {
        loading.dismiss();
        this.toastCtrl.create(
          {
            message: "Error creando la generación, verifique los campos",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
          }
        );
      }
    )
  }

  public async saveEngine() {
    let loading = await this.loadingCtrl.create({});
    loading.present();

    //
    this.carSRV.createEngine(this.model, this.serie, this.newEngine).then(
      (data) => {
        loading.dismiss();
        this.modalCtrl.dismiss();
        this.toastCtrl.create(
          {
            message: "Motor creado correctamente",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
            location.reload();
          }
        );
      }
    ).catch(
      (error) => {
        loading.dismiss();
        this.toastCtrl.create(
          {
            message: "Error creando el motor, verifique los campos",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
          }
        );
      }
    )
  }

  public async saveEquipment() {
    let loading = await this.loadingCtrl.create({});
    loading.present();

    //
    this.carSRV.createEquipment(this.engine, this.newEquipment).then(
      (data) => {
        loading.dismiss();
        this.modalCtrl.dismiss();
        this.toastCtrl.create(
          {
            message: "Equipamiento creado correctamente",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
            //location.reload();
          }
        );
      }
    ).catch(
      (error) => {
        loading.dismiss();
        this.toastCtrl.create(
          {
            message: "Error creando el Equipamiento, verifique los campos",
            duration: 3000
          }
        ).then(
          (toast) => {
            toast.present();
          }
        );
      }
    )
  }
}
