import {Component} from '@angular/core';
import { Router } from '@angular/router';
import {ModalController} from '@ionic/angular';
import {LoginPage} from './login/login.page';
import {UserService} from './services/User.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    {title: 'Dashboard', url: '/dashboard', icon: 'home'},
    {title: 'Conductores', url: '/customer-list', icon: 'people'},
    {title: 'Clientes', url: '/client-list', icon: 'people-circle'},
    {title: 'Campañas', url: '/campaign-list', icon: 'apps'},
    {title: 'Presupuestos', url: '/budget-list', icon: 'document-text'},
    {title: 'Pagos', url: '/payments', icon: 'card'},
    {title: 'Promociones', url: '/promotions-list', icon: 'pricetags'},
    {title: 'Vehículos', url: '/vehicle-manager', icon: 'car-sport'},
    {title: 'Usuarios admin', url: '/users', icon: 'accessibility'}
  ];
  public profile: any = null;

  constructor(
    public modalCtrl: ModalController,
    private userSRV: UserService,
    private router: Router
  ) {
    if (localStorage.getItem('pca_token') == null || typeof localStorage.getItem('pca_token') == 'undefined') {
      this.showLogin();
    } else {
      this.fetchProfile();
    }
  }

  private async showLogin() {
    let loginModal = await this.modalCtrl.create({component: LoginPage});
    loginModal.onDidDismiss().then(() => {
      if (localStorage.getItem('pca_token') == null || typeof localStorage.getItem('pca_token') == 'undefined') {
        this.showLogin();
      }
    });
    loginModal.present();
  }

  /**
   * D-loads the profile
   */
  private fetchProfile() {
    this.userSRV.fetchProfile().then(
      (data) => {
        this.profile = data;
      }
    ).catch(
      (error) => {
        console.error(error);
      }
    );
  }
  
  public async openLink(path) {
    this.router.navigateByUrl(path);
  }
}
