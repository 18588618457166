import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'folder/Inbox',
    pathMatch: 'full'
  },
  {
    path: 'campaign-list',
    loadChildren: () => import('./campaign-list/campaign-list.module').then(m => m.CampaignListPageModule)
  },
  {
    path: 'campaign-form/:id',
    loadChildren: () => import('./campaign-form/campaign-form.module').then(m => m.CampaignFormPageModule)
  },
  {
    path: 'budget-list',
    loadChildren: () => import('./budget-list/budget-list.module').then(m => m.BudgetListPageModule)
  },
  {
    path: 'budget-form/:id',
    loadChildren: () => import('./budget-form/budget-form.module').then(m => m.BudgetFormPageModule)
  },
  {
    path: 'customer-list',
    loadChildren: () => import('./customer-list/customer-list.module').then(m => m.CustomerListPageModule)
  },
  {
    path: 'customer-form/:id',
    loadChildren: () => import('./customer-form/customer-form.module').then(m => m.CustomerFormPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsPageModule)
  },
  {
    path: 'payments-requested',
    loadChildren: () => import('./payments-requested/payments-requested.module').then(m => m.PaymentsRequestedPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule)
  },
  {
    path: 'vehicle-manager',
    loadChildren: () => import('./vehicle-manager/car-brand-manager/car-brand-manager.module').then(m => m.CarBrandManagerPageModule)
  },
  {
    path: 'vehicle-manager/car-model-manager/:brand_id',
    loadChildren: () => import('./vehicle-manager/car-model-manager/car-model-manager.module').then(m => m.CarModelManagerPageModule)
  },
  {
    path: 'vehicle-manager/car-details-manager/:model_id',
    loadChildren: () => import('./vehicle-manager/car-details-manager/car-details-manager.module').then(m => m.CarDetailsManagerPageModule)
  },
  {
    path: 'client-list',
    loadChildren: () => import('./client-list/client-list.module').then(m => m.ClientListPageModule)
  },
  {
    path: 'client-form/:id',
    loadChildren: () => import('./client-form/client-form.module').then(m => m.ClientFormPageModule)
  },
  {
    path: 'promotions-list',
    loadChildren: () => import('./promotions-list/promotions-list.module').then(m => m.PromotionsListPageModule)
  },
  {
    path: 'promotions-form/:id',
    loadChildren: () => import('./promotions-form/promotions-form.module').then(m => m.PromotionsFormPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersPageModule)
  },
  {
    path: 'user/detail/:id',
    loadChildren: () => import('./users/user-detail/user-detail.module').then(m => m.UserDetailPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
