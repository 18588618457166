import {Component, OnInit} from '@angular/core';
import {LoadingController, ToastController} from '@ionic/angular';
import {UserService} from '../services/User.service';
import {Router} from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage implements OnInit {

  public username;
  public passwd;

  constructor(
    private loadingCtrl: LoadingController,
    private toastCtrl: ToastController,
    private userSRV: UserService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  public async doLogin() {
    let loading = await this.loadingCtrl.create({});
    loading.present();

    let email: any = document.querySelector('#email');
    let password: any = document.querySelector('#password');

    this.userSRV.doLogin(email.value, password.value).then(
      (data) => {
        if (data.result == 'ok') {
          this.router.navigateByUrl('/dashboard');
          localStorage.setItem('pca_token', data.token);
          location.reload();
          this.toastCtrl.create(
            {
              message: 'Bienvenido',
              duration: 5000
            }
          ).then(
            (toast) => {
              toast.present();
            }
          );
        } else {
          this.toastCtrl.create(
            {
              message: 'Credenciales incorrectas',
              duration: 5000
            }
          ).then(
            (toast) => {
              toast.present();
            }
          );
        }
        loading.dismiss();
      }
    ).catch(
      (error) => {
        console.error(error);
        loading.dismiss();
        this.toastCtrl.create(
          {
            message: 'Error iniciando sesión',
            duration: 5000
          }
        ).then(
          (toast) => {
            toast.present();
          }
        );
      }
    )
  }
}
