import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {getAPIEndpoint} from '../app.module';

@Injectable()
export class CarService {
  constructor(
    private http: HttpClient
  ) {
  }

  public prettifyCar(fk_brand, fk_model, fk_year, fk_serie, fk_engine, fk_color): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/pretify', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_brand: fk_brand,
        fk_model: fk_model,
        fk_year: fk_year,
        fk_serie: fk_serie,
        fk_engine: fk_engine,
        fk_color: fk_color
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  /**
   * Return a list with all the posts items
   */
  public getAllBrands(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/brand/list', JSON.stringify({
        token: localStorage.getItem('pca_token')
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }


  /**
   * Return a list with all the posts items
   */
  public getModels(brandID): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/model/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        brandID: brandID
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }


  public getModel(modelId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/model/get', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        modelId: modelId
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public getYears(modelId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/year/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_model: modelId
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public getBodyTypes(modelId, generationId): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/body_type/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_model: modelId,
        fk_generation: generationId
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public getEngines(fk_serie, fk_model): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/engine/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_serie: fk_serie,
        fk_model: fk_model
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }


  public getCarOptions(fk_equipment): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/option/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_equipment: fk_equipment
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public getEquipments(fk_engine): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/equipment/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_engine: fk_engine
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public getColors(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/colors', JSON.stringify({
        token: localStorage.getItem('pca_token')
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public getSeries(fk_model, fk_year): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/serie/list', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_model: fk_model,
        fk_year: fk_year
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public createGeneration(fk_model, generation): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/year/new', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_model: fk_model,
        generation: generation
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public createSerie(fk_model, fk_year, serie): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/serie/new', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_model: fk_model,
        fk_year: fk_year,
        serie: serie
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public createEngine(fk_model, fk_serie, engine): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/engine/new', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_model: fk_model,
        fk_serie: fk_serie,
        engine: engine
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public createEquipment(fk_engine, equipment): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/equipment/new', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        fk_engine: fk_engine,
        equipment: equipment
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public createBrand(brand): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/brand/new', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        brand: brand
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }


  public createModel(model, fk_brand): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/model/new', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        model: model,
        fk_brand: fk_brand
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public deleteYear(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/year/delete', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        id: id,
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public deleteEngine(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/engine/delete', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        id: id,
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public deleteSerie(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/serie/delete', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        id: id,
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public deleteEquipment(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/equipment/delete', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        id: id,
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }
  public deleteBrand(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/brand/delete', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        id: id,
      }))
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public uploadFileForBrand(formData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/brand/upload', formData)
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  public uploadFileForCampaign(formData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/campaign/upload', formData)
        .subscribe
        (
          (data: any) => {
            resolve(data);
          },
          error => {
            reject(error);
          }
        );
    });
  }


  public updateBrand(brand_id, name): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(getAPIEndpoint() + 'car/brand/update', JSON.stringify({
        token: localStorage.getItem('pca_token'),
        brand_id,
        name
      }))
          .subscribe
          (
              (data: any) => {
                resolve(data);
              },
              error => {
                reject(error);
              }
          );
    });
  }

}
